/*! nouislider - 9.1.0 - 2016-12-10 16:00:32 */


/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */

 .noUi-target,
 .noUi-target * {
   -webkit-touch-callout: none;
   -ms-touch-action: none;
   user-select: none;
   box-sizing: border-box;
 }

 .noUi-base {
   width: 100%;
   height: 100%;
   position: relative;
 }

 .noUi-origin {
   position: absolute;
   right: 0;
   top: 0;
   left: 0;
   bottom: 0;
 }

 .noUi-handle {
   position: relative;
   z-index: 1;
   box-sizing: border-box;
 }

 .noUi-stacking .noUi-handle {
   z-index: 10;
 }

 .noUi-state-tap .noUi-origin {
   transition: left 0.3s, top 0.3s;
 }

 .noUi-state-drag * {
   cursor: inherit !important;
 }

 .noUi-horizontal {
   height: 10px;
 }

 .noUi-handle {
   box-sizing: border-box;
   width: 14px;
   height: 14px;
   left: -10px;
   top: -6px;
   cursor: pointer;
   border-radius: 100%;
   transition: all 0.2s ease-out;
   border: 1px solid;
   background: #FFFFFF;
   box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
 }

 .noUi-vertical .noUi-handle {
   margin-left: 5px;
   cursor: ns-resize;
 }

 .noUi-horizontal.noUi-extended {
   padding: 0 15px;
 }

 .noUi-horizontal.noUi-extended .noUi-origin {
   right: -15px;
 }

 .noUi-background {
   height: 2px;
   margin: 20px 0;
 }

 .noUi-origin {
   margin: 0;
   border-radius: 0;
   height: 2px;
   background: #c8c8c8;
 }
 .noUi-origin[style^="left: 0"] .noUi-handle {
   background-color: #fff;
   border: 2px solid #c8c8c8;
 }
 .noUi-origin[style^="left: 0"] .noUi-handle.noUi-active {
   border-width: 1px;
 }

 .noUi-target {
   border-radius: 3px;
 }

 .noUi-horizontal {
   height: 2px;
   margin: 15px 0;
 }

 .noUi-vertical {
   height: 100%;
   width: 2px;
   margin: 0 15px;
   display: inline-block;
 }

 .noUi-handle.noUi-active {
   transform: scale3d(2, 2, 1);
 }

 [disabled].noUi-slider {
   opacity: 0.5;
 }

 [disabled] .noUi-handle {
   cursor: not-allowed;
 }

 .slider {
   background: #c8c8c8;
 }
 .c-1-color{
     background-color: blue;
 }
 .c-2-color{
     background-color: white;
 }
 .slider.noUi-connect {
   background-color: #7AC29A;
 }
 .slider .noUi-handle {
   border-color: #7AC29A;
 }
 .slider.slider-info .noUi-origin:first-child{
   background-color: #68B3C8;
 }
 .slider.slider-info .noUi-handle {
   border-color: #68B3C8;
 }
 .slider.slider-success .noUi-connect,
 .slider.slider-success.noUi-connect {
   background-color: #68B3C8;
 }
 .slider.slider-success .noUi-handle {
   border-color: #68B3C8;
 }
 .slider.slider-warning .noUi-connect,
 .slider.slider-warning.noUi-connect {
   background-color: #ff9800;
 }
 .slider.slider-warning .noUi-handle {
   border-color: #ff9800;
 }
 .slider.slider-danger .noUi-connect,
 .slider.slider-danger.noUi-connect {
   background-color: #f44336;
 }
 .slider.slider-danger .noUi-handle {
   border-color: #f44336;
 }
