@mixin input-size($padding-vertical, $padding-horizontal, $height){
    padding: $padding-vertical $padding-horizontal;
    height: $height;
}

@mixin light-form(){
    border-radius: 0;
    border:0;
    padding: 0;
    background-color: transparent;

}

@mixin placeholder() {
  &::-moz-placeholder {@content; } // Firefox
  &:-ms-input-placeholder {@content; } // Internet Explorer 10+
  &::-webkit-input-placeholder  {@content; } // Safari and Chrome
}
